import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      css={{
        width: '100vw',
        maxWidth: 1280,
        margin: '0 auto',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <h1 css={{ textAlign: 'center' }}>404</h1>
    </div>
  </Layout>
)

export default NotFoundPage
